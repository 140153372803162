<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
              Users
              <v-spacer/>
            </v-card-title>
            <v-card-text class="pa-0">

              <v-data-table
                :headers="headers"
                :items="terminalUsers"
                single-select
                return-object
                class="elevation-5"
              >
              <template v-slot:item.TerminalUserName="{ item }">
                  {{ item.TerminalUserName ? item.TerminalUserName : 'No data available' }}
              </template>
              <template v-slot:item.SecLevel="{ item }">
                  {{ item.SecLevel ? item.SecLevel : 'No data available' }}
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-row class="mt-2 mb-2">
                    <v-col cols="4">
                      <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectedStallHolder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="StallHolder"
                        @change="haddleChangeStallholders"

                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              <v-icon small color="red lighten-1">
                                mdi-information
                              </v-icon>
                              No event found
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add Users
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                          <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >

                            <label>
                              <B>Event Name:-</B> 
                                {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}
                            </label>
                            <br/>
                            <label>
                              <B>Stallholder Name:-</B> 
                                {{ StallHolderName ? StallHolderName : 'N/A'}}
                            </label>

                            <v-text-field
                              v-model="editedItem.TerminalUserName"
                              label="Terminal UserName"
                              :rules="[v => !!v || 'Terminal UserName is required']"
                            ></v-text-field>

                            <v-text-field
                              v-model="editedItem.Pin"
                              type="number"
                              label="Pin"
                              :rules="[v => !!v || 'Pin Level is required']"
                            ></v-text-field>

                            <v-combobox
                              v-model="editedItem.SecLevel"
                              :items="SecLevel"
                              label="Security Level"
                              :rules="[v => !!v || 'Security Level is required']"
                              required
                            ></v-combobox>
  
                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px" dark>
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to Delete User ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No users for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      defaltSelect: true,

      headers: [
        { text: "Terminal User Name", value: "TerminalUserName"},
        { text: "Security Level", value: "SecLevel"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      
      stallHolders: [],
      StallHolderName: '',
      stallHoldersList: [],

      editedIndex: -1,
      editedItem: {
        EventId: '',
        StallholderId: '',
        TerminalUserName: '',
        Pin: '',
        SecLevel: ''
      },
      defaultItem: {
        EventId: '',
        StallholderId: '',
        TerminalUserName: '',
        Pin: '',
        SecLevel: ''
      },
  
      valid: true,
      SecLevel: ['Manager', 'User']
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      'allStallHolders', 'allTerminalUsers']),
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED MAKE API REQUEST", value)
        }
      },
      selectedStallHolder: {
        get() {
          return this.allStallHolders[0]
        },
        set (value) {
          console.log("allStallHolders IS CHANGED MAKE API REQUEST ----1", value)
        }
      },
      terminalUsers: {
        get() {
          return this.allTerminalUsers
        },
        set (value) {
          console.log("terminalUsers IS CHANGED MAKE API REQUEST ----1", value)
        }
      },
    },
  
    watch: {
      dialog (val) {
        val || this.close()
        if (this.defaltSelect === true) {
          this.StallHolderName = this.selectedStallHolder.StallholderName;
          this.editedItem.StallholderId = this.selectedStallHolder.StallholderId;
        }
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
  
    created() {},
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter',
      // 'setAllEvents',
      'setFilterStallHolders', 'setAllTerminalUsers']),
      ...mapActions('admin', ['createEvent']),

      haddleChangeStallholders: function(obj) {
        this.defaltSelect = false;
        this.editedItem.StallholderId = obj.StallholderId;
        this.StallHolderName = obj.StallholderName;

        this.setAllTerminalUsers(obj.TerminalUsers)
      },

      editItem (item) {
        this.editedIndex = this.terminalUsers.indexOf(item)

        this.editedItem.TerminalUserId = item.TerminalUserId;
        this.editedItem.StallholderId = item.StallholderId;
        let selectedObj = this.allStallHolders.filter(element => {
          return element.StallholderId === item.StallholderId;
        });
       
        this.selectedStallHolder = selectedObj[0];
        this.editedItem.TerminalUserName = item.TerminalUserName;
        this.editedItem.Pin = item.Pin;
        this.editedItem.SecLevel = item.SecLevel;

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.terminalUsers.indexOf(item)
        this.editedItem.TerminalUserId = item.TerminalUserId;
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.terminalUsers.splice(this.editedIndex, 1)
        
        try {
          if (this.editedItem.TerminalUserId) {
              let event_item = [
                  {
                      Type: "TerminalUser",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createEvent(submitEvent).then(response => {
                  console.log(response)

                  iziToast.success({
                      title: 'User',
                      message: 'Successfully delete record!',
                      position: 'topRight'
                  });

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Stallholder',
                      message: 'Fail to delete record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(this.terminalUsers[this.editedIndex], this.editedItem)
          } else {
            this.terminalUsers.push(this.editedItem)
          }

          try {
            if (this.$refs.form.validate()) {
                this.editedItem.EventId = this.selectedEvent.EventId;
                let event_item = [
                    {
                        Type: "TerminalUser",
                        Action: this.editedIndex === -1 ? 'create' : 'update',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }
                this.createEvent(submitEvent).then(response => {
                    console.log("response")
                    console.log(response)

                    iziToast.success({
                        title: 'User',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                }).catch(err => {
                    console.log("err")
                    console.log(err)

                    iziToast.warning({
                        title: 'User',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
          this.close()
        }
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>