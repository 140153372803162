<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              
              <v-icon left dense> mdi-home </v-icon>
              Vouchers
              <v-spacer/>

            </v-card-title>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :items="voucherList"
                single-select
                class="elevation-5"
              >
              <template v-slot:item.VoucherName="{ item }">
                  {{ item.VoucherName ? item.VoucherName : 'No data available' }}
              </template>
              <template v-slot:item.Stalls="{ item }">                
                <span v-for="(stall, index) in item.Stallholders[0].Stalls" :key="index">
                  - {{stall.StallName}} <br/>
                </span>
              </template>
              <template v-slot:item.TextColour="{ item }">
                  <div class="v-color-picker__color"><div :style="{ 'background': item.TextColour }"></div></div>
              </template>
              <template v-slot:item.ButtonColour="{ item }">
                  <div class="v-color-picker__color"><div :style="{ 'background': item.ButtonColour }"></div></div>
              </template>
              <template v-slot:item.OrderInt="{ item }">
                  {{ item.OrderInt}}
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <v-row class="mt-2 mb-2">
                  <v-col cols="12"></v-col>
                </v-row>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                    dark
                  >
                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add voucher
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                         <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >
                            
                            <label><B>Event Name:-</B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>

                            <v-row class="mt-2">
                              <v-col cols="6">
                                
                                <v-combobox
                                  v-model="editedItem.Stalls"
                                  :items="allStalls"
                                  multiple
                                  return-object
                                  label="Stalls"
                                  item-text="StallName"
                                  @change="haddleChangeSH"
                                >
                                </v-combobox>
                              </v-col>
                            </v-row>

                            <v-text-field
                              v-model="editedItem.VoucherName"
                              label="Voucher Name"
                              :rules="[v => !!v || 'Voucher Name is required']"
                              required
                              ></v-text-field>

                            <v-text-field
                              v-model="editedItem.OrderInt"
                              type="number"
                              label="Order Prefix"
                              :rules="[v => !!v || 'Order Prefix is required']"
                              required
                              ></v-text-field>
                              
                            <v-row>
                                <v-col cols="6">
                                    <label>Text Colour</label>
                                    <v-color-picker
                                        dot-size="25"
                                        mode="hexa"
                                        hide-mode-switch
                                        swatches-max-height="150"
                                        v-model="editedItem.TextColour"
                                        required
                                    ></v-color-picker>
                                </v-col>

                                <v-col cols="6">
                                    <label>Button Colour</label>
                                    <v-color-picker
                                        dot-size="25"
                                        mode="hexa"
                                        hide-mode-switch
                                        swatches-max-height="150"
                                        v-model="editedItem.ButtonColour"
                                        required
                                    ></v-color-picker>
                                </v-col>
                            </v-row>

                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete Voucher?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  Updating with new data....
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,

      headers: [
        { text: "Voucher Name", value: "VoucherName"},
        { text: "Stalls", value: "Stalls"},
        { text: "Text Colour", value: "TextColour"},
        { text: "Button Colour", value: "ButtonColour"},
        { text: "Order Prefix", value: "OrderInt"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      editedIndex: -1,
      editedItem: {
        VoucherName: '',
        TextColour: '',
        ButtonColour: '',
        OrderInt: 0,
        Stallholders: []
      },
      defaultItem: {
        VoucherName: '',
        TextColour: '',
        ButtonColour: '',
        OrderInt: 0,
        Stallholders: []
      },

      stallsList: [],
  
      valid: true
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      'allStallHolders', 'allStalls', 'allVouchers']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Voucher' : 'Edit Voucher'
      },
      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED MAKE API REQUEST", value)
        }
      },
      // selectedStall: {
      //   get() {
      //     return this.allStalls[0]
      //   },
      //   set (value) {
      //     console.log("allStallHolders IS CHANGED MAKE API REQUEST ----1", value)
      //   }
      // },
      voucherList: {
        get() {
          console.log('GET', this.allVouchers)
          return this.allVouchers;
        },
        set (val) {
          // console.log('SET', this.allVouchers)

          this.setAllVouchers(val)
        }
      }
    },
  
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
  
    created() {
    },

    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter',
      // 'setAllEvents',
      'setFilterStallHolders', 'setAllVouchers']),
      ...mapActions('admin', ['createEvent']),

      haddleChangeSH: function (obj) {

        // this.stallsList.push(obj)
        console.log("obj")
        console.log(obj)
      },

      editItem (item) {
        this.editedItem.Stalls = item.Stallholders[0].Stalls
          
        this.editedIndex = this.voucherList.indexOf(item)

        this.editedItem.VoucherId = item.VoucherId;
        this.editedItem.VoucherName = item.VoucherName;
        this.editedItem.TextColour = item.TextColour;
        this.editedItem.ButtonColour = item.ButtonColour;
        this.editedItem.OrderInt = item.OrderInt;

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.voucherList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.voucherList.splice(this.editedIndex, 1)
        
        try {
          if (this.editedItem.ColourId) {
              let event_item = [
                  {
                      Type: "Voucher",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createEvent(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)

                  iziToast.success({
                      title: 'Voucher',
                      message: 'Successfully delete record!',
                      position: 'topRight'
                  });

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Voucher',
                      message: 'Fail to delete record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {
          this.editedItem.EventId = this.selectedEvent.EventId;
          this.editedItem.OrderInt = parseInt(this.editedItem.OrderInt);

          console.log('SAVE:',this.editedItem)

          let processedStalls = []

          for (let i = 0; i < this.editedItem.Stalls.length; i++) {
            processedStalls[i] = this.editedItem.Stalls[i].StallId
          }
          
          console.log('SAVE 2:',processedStalls)
          
          let Stallholder = [
            {
              Stalls: processedStalls
            }
          ]
          this.editedItem.Stallholders = Stallholder;
          delete this.editedItem.Stalls

          // this.editedItem.ButtonColour = this.editedItem.ButtonColour
          
          console.log('SAVE 3:',this.editedItem)

          try {
            if (this.$refs.form.validate()) {

                let event_item = [
                    {
                        Type: "Voucher",
                        Action: this.editedIndex === -1 ? 'Create' : 'Update',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }
                console.log("submitEvent")
                console.log(submitEvent)
                this.createEvent(submitEvent).then(response => {
                    console.log("response")
                    console.log(response)

                    iziToast.success({
                        title: 'Voucher',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                }).catch(err => {
                    console.log("err")
                    console.log(err)

                    iziToast.warning({
                        title: 'Voucher',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
          this.close()
        }
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>