<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
              Stalls
              <v-spacer/>
              <v-btn
                x-small
                dark
                @click="haddleClick()"
              >
                <v-icon left x-small>
                  mdi-plus
                </v-icon>
                Add Stall
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">

              <v-row class="mt-2 mb-2 ml-1">
                <v-col cols="2">
                  <v-autocomplete
                    :items="allStallHolders"
                    v-model="selectedStallHolder"
                    item-text="StallholderName"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="StallHolder"
                    @change="haddleChangeSH"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon small color="red lighten-1">
                            mdi-information
                          </v-icon>
                          No event found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              
              <v-data-table
                :headers="headers"
                :items="selectedStall"
                single-select
                return-object
                class="elevation-5"
              >
              <template v-slot:item.StallName="{ item }">
                  {{ item.StallName ? item.StallName : 'No data available' }}
              </template>
              <template v-slot:item.Abn="{ item }">
                  {{ item.Abn ? item.Abn : 'No data available' }}
              </template>
              <template v-slot:item.PaymentProfile="{ item }">
                  {{ item.PaymentProfile ? item.PaymentProfile.toString() : 'No data available' }}
              </template>
              <template v-slot:item.PrintingProfile="{ item }">
                  {{ item.PrintingProfile ? item.PrintingProfile : 'No data available' }}
              </template>
              <template v-slot:item.StandardDrinkLimit="{ item }">
                  {{ item.StandardDrinkLimit ? item.StandardDrinkLimit : 'No data available' }}
              </template>
              <template v-slot:item.CardSurchargePercentage="{ item }">
                  {{ item.CardSurchargePercentage ? item.CardSurchargePercentage : 'No data available' }}
              </template>
              <template v-slot:top>
                  <v-dialog dark v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete this Stall?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                <!-- </v-toolbar> -->
              </template>
              
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No stalls for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import {
    mapState,
    mapMutations,
    mapActions
  } from 'vuex'
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,

      isSelect: false,

      headers: [
        { text: "Name", value: "StallName"},
        { text: "ABN", value: "Abn"},
        { text: "Payment Profile", value: "PaymentProfile"},
        { text: "Printing Profile", value: "PrintingProfile"},
        { text: "Standard Drink Limit", value: "StandardDrinkLimit"},
        { text: "Card Surcharge (%)", value: "CardSurchargePercentage"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      editedIndex: -1,

      editedItem: {
        StallHolderName: '',
        Event: '',
        Client: ''
      },
      defaultItem: {
        StallHolderName: '',
        Event: '',
        Client: ''
      },
  
      valid: true,
      eventNameRules: [
        v => !!v || 'Event Name is required',
      ],
      eventPrintRules: [
        v => !!v || 'Printing Profile is required',
      ],
      eventPercentageRules: [
        v => (v && v > 0) || 'Card Surcharge Percentage must be greater than 0'
      ],
  
      PrintingList: [
        {
          id: 1,
          name: "Print"
        },
        {
          id: 2,
          name: "Prompt"
        },
        {
          id: 3,
          name: "Bypass"
        }
      ],
  
      POSTypes: ['YES', 'NO'],
      Years: ['2023', '2022', '2021', '2020', '2019']
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      'allStallHolders', 'allStalls']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Event' : 'Edit Event'
      },
      selectedStallHolder: {
        get() {
          return this.allStallHolders[0]
        },
        set (value) {
          console.log("allStallHolders IS CHANGED MAKE API REQUEST ----1", value)
        }
      },
      selectedStall: {
        get() {
          return this.allStalls
        },
        set (value) {
          console.log("Stall IS CHANGED MAKE API REQUEST-----1", value)
        }
      }
    },

    updated: function () {
      console.log("update Stall--111")
    },
  
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
  
    created() {
    },
    
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter',
      // 'setAllEvents',
      'setAllStalls', 'setFilterStallHolders',
      // 'setFilterStalls'
    ]),
      ...mapActions('admin', ['createEvent']),
      haddleChangeSH: function (obj) {
        this.isSelect = true;
        this.setAllStalls(obj.Stalls)
        localStorage.setItem('setStallHolderselectObj', JSON.stringify(obj))
      },
      editItem (item) {
        localStorage.setItem('setStallObj', JSON.stringify(item))

        this.$router.push({
          name: 'Stall Configuration',
          params: { id: item.StallId }
        })

      },
  
      deleteItem (item) {
        this.editedIndex = this.selectedStall.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.selectedStall.splice(this.editedIndex, 1)

        try {
          if (this.editedItem.StallId) {
              let event_item = [
                  {
                      Type: "Stall",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createEvent(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)
              }).catch(err => {
                  console.log("err")
                  console.log(err)
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(this.selectedStall[this.editedIndex], this.editedItem)
          } else {
            this.selectedStall.push(this.editedItem)
          }
          this.close()
        }
      },

      haddleClick: function () {

        if (this.isSelect === false) {
          localStorage.setItem('setStallHolderselectObj', JSON.stringify(this.selectedStallHolder))
        }

        this.$router.push({
          name: 'Stall Configuration',
          params: { id: 0 }
        })
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>