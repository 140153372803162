<template>
  <v-container fluid>
   
    <v-tabs
      v-model="tab"
      dark
      class="pl-3 pr-3"
      @change="haddleTabClick"
    >
      <v-tabs-slider></v-tabs-slider>
      
      <v-tab href="#tab-0">
        Events
      </v-tab>

      <v-tab href="#tab-1">
        Stallholders
      </v-tab>

      <v-tab href="#tab-2">
        Users
      </v-tab>

      <v-tab href="#tab-3">
        Stalls
      </v-tab>

      <v-tab href="#tab-4">
        Terminals
      </v-tab>

      <v-tab href="#tab-5">
        Vouchers
      </v-tab>

      <v-tab href="#tab-6">
        Devices
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab" dark>
      
      <v-tab-item
        :value="'tab-0'"
      >
        <v-card flat style="background-color: #272b30;">
          <events/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-1'"
      >
        <v-card flat style="background-color: #272b30;">
          <stall-holders/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-2'"
      >
        <v-card flat style="background-color: #272b30;">
          <users/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-3'"
      >
        <v-card flat style="background-color: #272b30;">
          <stall/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-4'"
      >
        <v-card flat style="background-color: #272b30;">
          <terminals/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-5'"
      >
        <v-card flat style="background-color: #272b30;">
          <vouchers/>
        </v-card>
      </v-tab-item>

      <v-tab-item
        :value="'tab-6'"
      >
        <v-card flat style="background-color: #272b30;">
          <devices/>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>
<script>
  import Events from '../EventManagement/Events/events.vue'
  import StallHolders from '../EventManagement/Stallholders/stallholders.vue'
  import stall from '../EventManagement/Stalls/stalls.vue'
  import terminals from '../EventManagement/Terminals/terminals.vue'
  import users from '../EventManagement/Users/users.vue'
  import vouchers from '../EventManagement/Vouchers/vouchers.vue'
  import devices from '../EventManagement/Devices/devices.vue'
  export default {
    components: {
      Events,
      StallHolders,
      stall,
      terminals,
      users,
      vouchers,
      devices
    },
    data () {
      return {
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    },
    created() {
      this.checkSelectedTab();
    },
    methods: {
      haddleTabClick: function(val) {
        localStorage.setItem('setSelectedTab', JSON.stringify(val))
  
      },
      checkSelectedTab: function() {
        let selectedName = JSON.parse(localStorage.getItem('setSelectedTab'));
        if (selectedName && selectedName !== null) {
          this.tab = selectedName;
        }
      }
    }
  }
//   export default {
//     name: 'Home'
//   }

</script>

<style scoped>

body {
    background-color: #272b30;
}
h1{
  color: #a09c94;
  font-family: sans-serif;
}
p{
  color: #6e6e6e;
  font-family: sans-serif;
}
</style>