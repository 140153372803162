<template>
  <v-container fluid>

    <v-row>        
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-home </v-icon>
            Terminals
            <v-spacer/>
            <v-btn
              x-small
              dark
              @click="haddleClick()"
              :disabled="!selectedStall.StallId"
            >
              <v-icon left x-small>
                mdi-plus
              </v-icon>
              Add Terminal
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">

            <v-row class="mt-2 mb-2 ml-1">
              <v-col cols="2">
                <v-autocomplete
                  :items="allStallHolders"
                  v-model="selectedStallHolder"
                  item-text="StallholderName"
                  return-object
                  filled
                  dense
                  outlined
                  hide-details
                  label="StallHolders"
                  @change="handleChangeStallholder"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon small color="red lighten-1">
                          mdi-information
                        </v-icon>
                        No Stallholders found
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              
              <v-col cols="2">
                <v-autocomplete
                  :items="stalls"
                  v-model="selectedStall"
                  item-text="StallName"
                  return-object
                  filled
                  dense
                  outlined
                  hide-details
                  label="Stalls"
                  @change="haddleChangeStall"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon small color="red lighten-1">
                          mdi-information
                        </v-icon>
                        No Stalls found
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

            </v-row>
            
            <v-data-table
              :headers="headers"
              :items="terminals"
              sort-by="TerminalId"
              single-select
              return-object
              class="elevation-5"
            >            
              <!-- :items="terminals.slice(0, 10)" -->
            <template v-slot:item.TerminalNumber="{ item }">
                {{ item.TerminalNumber ? item.TerminalNumber : 'No data available' }}
            </template>
            <template v-slot:item.OrderNumber="{ item }">
                {{ item.OrderNumber ? item.OrderNumber : 'No data available' }}
            </template>
            <template v-slot:item.PaymentProfile="{ item }">
                {{ item.PaymentProfile ? item.PaymentProfile.join(', ') : 'No data available' }}
            </template>
            <template v-slot:item.PrintingProfile="{ item }">
                {{ item.PrintingProfile ? item.PrintingProfile : 'No data available' }}
            </template>

            <template v-slot:top>
                <v-dialog dark v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete Terminal?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              <!-- </v-toolbar> -->
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
              >
                No terminals for this event
              </v-btn>
            </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import 'izitoast/dist/css/iziToast.min.css'; // loading css 
import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

export default {
  data: () => ({
    
    newStallholder: {},
    selectedStall: '',
    
    dialog: false,
    dialogDelete: false,

    isSelect: false,

    headers: [
      { text: "Terminal Number", value: "TerminalNumber",  width: "20%"},
      { text: "OrderNumber", value: "OrderNumber"},
      { text: "Payment Profile", value: "PaymentProfile",  width: "20%"},
      { text: "Printing Profile", value: "PrintingProfile", width: "20%"},
      { text: 'Actions', value: 'actions', sortable: false }
    ],

    editedIndex: -1,
    editedItem: {
      StallHolderName: '',
      Event: '',
      Client: ''
    },
    defaultItem: {
      StallHolderName: '',
      Event: '',
      Client: ''
    }
  }),

  computed: {
    ...mapState('filter', ['globalEventFilter',
    // 'allEvents',
    'allStallHolders', 'allStalls',
    // 'allTerminals'
  ]),

    formTitle () {
      return this.editedIndex === -1 ? 'New Event' : 'Edit Event'
    },
    
    terminals:{
      get() {
        return this.selectedStall ? this.selectedStall.Terminals : [];
        
      },
      set (value) {
        console.log("Terminals IS CHANGED MAKE API REQUEST ----1", value)
      }
    },
    
    stalls:{
      get() {

        console.log("this.newStallholderthis.newStallholderthis",this.selectedStallHolder)
        return this.newStallholder.Stalls;
        
      },
      set (value) {
        console.log("allStalls IS CHANGED MAKE API REQUEST ----1", value)
      }
    },

    selectedStallHolder: {

      get() {
        return this.newStallholder
      },
      set (value) {
        this.newStallholder = value
        console.log("allStallHolders IS CHANGED MAKE API REQUEST ----1", value)
      }
    },

  },

  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
    selectedStallHolder (val) {
      console.log('selectedStallHolder val',val)
    }
  },

  created() {
  },

  updated: function () {},
  methods: {
    ...mapMutations('filter', ['setGlobalEventFilter',
    // 'setAllEvents',
    // 'setFilterTerminals'
    ]),
    ...mapActions('admin', ['createEvent']),

    
    handleChangeStallholder: function (obj) {

      // obj.StallHolders
      this.newStallholder = obj
      localStorage.setItem('setStallHolderselectObj', JSON.stringify(this.newStallholder))

    },

    haddleChangeStall: function (obj) {
      
      // obj.Stalls
      this.stalls = obj.Stalls;
      this.selectedStall = obj

      this.isSelect = true;
      localStorage.setItem('setStallselectObj', JSON.stringify(this.selectedStall))
    },

    editItem (item) {
      console.log("edit object")
      console.log(item)
      localStorage.setItem('setTerminalObj', JSON.stringify(item))
      localStorage.setItem('setStallObj', JSON.stringify(item))
      localStorage.setItem('setNewObj', false)

      this.$router.push({
        name: 'Terminal Configuration',
        params: { id: item.TerminalId }
      })
    },

    deleteItem (item) {
      this.editedIndex = this.terminals.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.terminals.splice(this.editedIndex, 1)

      try {
        if (this.editedItem.TerminalId) {
            let event_item = [
                {
                    Type: "Terminal",
                    Action: "delete",
                    Properties: this.editedItem
                }
            ]
            
            let submitEvent = {
                event_items: event_item
            }
            this.createEvent(submitEvent).then(response => {
                console.log("response")
                console.log(response)

                iziToast.success({
                    title: 'Terminal',
                    message: 'Successfully delete record!',
                    position: 'topRight'
                });

            }).catch(err => {
                console.log("err")
                console.log(err)

                iziToast.warning({
                    title: 'Terminal',
                    message: 'Fail to delete record!',
                    position: 'topRight'
                });
            })
        }
      } catch (e) {
        console.log(e)
      }

      this.closeDelete()
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    haddleClick: function () {
      
      localStorage.setItem('setNewObj', true)

      // if (this.isSelect === false) {
      //   localStorage.setItem('setStallHolderselectObj', JSON.stringify(this.selectedStallHolder))
      // }

      this.$router.push({
        // path: '/event_configuration',
        name: 'Terminal Configuration',
        params: { id: 0 }
      })
    }
  }
}
</script>
<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>